import React from 'react';
import Layout, { TEMPLATE } from '../components/Layout';

const title = 'About';

const AboutPage = () => (
  <Layout title={title} template={TEMPLATE.TEXT} children={null} />
);

export default AboutPage;
